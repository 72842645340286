@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  outline: none;
}

.selectWithIcon {
  padding-left: 40px !important;
}
.selectWithIcon ::placeholder {
  color: #667085;
}
.authForm input {
  border-color: #cccfd6ec !important;
}
.chakra-input__left-addon {
  border-color: #cccfd6ec !important;
}
.businessRegForm input,
select,
textarea {
  border-color: #cccfd6ec !important;
}
.inputWithLeftItem {
  padding-left: 125px !important;
}
.PhoneInput {
  width: 100%;
  padding: 0 10px;
  background-color: transparent;
  border-radius: 0.743rem;
}
.PhoneInput input {
  background-color: transparent;
  height: 42px;
  width: 100%;
  outline: none;
}
img.emojione {
  margin: 0px !important;
  display: inline !important;

  height: auto;
  width: 50px;
}
.noPadding {
  padding: 0 !important;
}
@media (max-width: 900px) {
  .noPadMobile {
    padding: 0 !important;
  }
}
@keyframes ImgBounce {
  0% {
    transform: rotateZ(360deg);
  }
}
.loadingIcon svg {
  -webkit-animation: ImgBounce 1s infinite;
  animation: ImgBounce 1s infinite;
  width: 20%;
  height: 20%;
}

.loadingIcon2 svg {
  -webkit-animation: ImgBounce 1s infinite;
  animation: ImgBounce 1s infinite;
  width: 40%;
  height: 40%;
}

.currencyForm select {
  width: auto !important;
}

.react-datepicker-popper {
  z-index: 99999;
}

.rmdp-container {
  width: 100% !important;
}

.rmdp-container input {
  width: 100%;
  padding: 18px 16px;
}

.css-13cymwt-control {
  height: 48px !important;
  overflow-x: auto;
}
